import {
  schoolsByDistrict,
  districtRef,
  schoolRef
} from 'shared/firestore/refs'
import React, { createContext, useContext, useState, useEffect } from 'react'
import { alphaCompare } from 'shared/utils'

export const OrgsContext = createContext()

const districtAdminRoles = ['districtAdmin', 'ninedotsAdmin', 'ninedotsTeacher']

export const OrgsProvider = ({ adminOrgs = {}, role, children }) => {
  const [loading, setLoading] = useState(true)
  const [orgs, setOrgs] = useState({})
  const { districtId, schoolIds = [] } = adminOrgs
  const isDistrictAdmin = districtAdminRoles.includes(role)

  useEffect(() => {
    const listeners = []
    setLoading(true)
    const districtListener = districtRef(districtId).onSnapshot(snap => {
      const district = snap.data()
      listeners.push(districtListener)
      if (isDistrictAdmin) {
        /**
         * Retrieve the district and all schools in the district
         * if user is a district admin
         * */
        const schoolsListener = schoolsByDistrict(districtId).onSnapshot(
          snap => {
            setOrgs({
              district,
              schools: snap.docs.map(doc => doc.data()).sort(schoolSort)
            })
            setLoading(false)
          }
        )
        listeners.push(schoolsListener)
      } else if (schoolIds) {
        /**
         * Retrieve all schools the user is an admin in
         * */

        Promise.all(
          schoolIds.map(id =>
            schoolRef(id)
              .get()
              .then(snap => ({ ...snap.data(), id }))
          )
        ).then(schools => {
          setOrgs({
            district,
            schools: schools.sort(schoolSort)
          })

          setLoading(false)
        })

        /**
         * Watch schools to make data live
         */
        const schoolsListeners = schoolIds.map(id =>
          schoolRef(id).onSnapshot(snap => {
            const data = { ...snap.data(), id }
            setOrgs(orgs => ({
              district,
              schools: (orgs.schools || []).map(school =>
                school.id === data.id ? data : school
              )
            }))
          })
        )
        listeners.push(...schoolsListeners)
      }
    })
    return () => listeners.map(fn => fn())
  }, [districtId || schoolIds.join('.')])

  return (
    <OrgsContext.Provider value={{ orgs, schoolSort, loading }}>
      {children}
    </OrgsContext.Provider>
  )
}

const useOrgs = () => useContext(OrgsContext)

export default useOrgs

const schoolSort = (a, b) => alphaCompare(a, b, 'displayName')
