/**
 * @function gradeCompare
 * @description A function for sorting teachers by grade levels. Teachers who don't
 * have a designated grade level will be sorted to the top regardless of sort order.
 * @param {Object} a
 * @param {Object} b
 * @param {String} sortOrder
 * @returns {Number}
 */
import { gradeValueToId } from 'shared/utils/data'

export default function gradeCompare(a, b, sortOrder) {
  const { grades: aGrades = [] } = a
  const { grades: bGrades = [] } = b

  if (aGrades.length >= 1 && bGrades.length >= 1) {
    const aGradeIds = aGrades.map(grade => gradeValueToId(grade))
    const bGradeIds = bGrades.map(grade => gradeValueToId(grade))

    const aGrade =
      sortOrder === 'descend' ? Math.max(...aGradeIds) : Math.min(...aGradeIds)
    const bGrade =
      sortOrder === 'descend' ? Math.max(...bGradeIds) : Math.min(...bGradeIds)

    return aGrade - bGrade
  } else if (sortOrder === 'ascend') {
    return aGrades.length <= 0 ? -1 : 1
  } else {
    return aGrades.length <= 0 ? 1 : -1
  }
}
