/**
 * @file a hook for getting school survey data by class
 * @author Anh Tran
 */
import { aggregateSurveyResults, filterClassBySelectedGrade } from './utils.js'
import { useFirestoreValues } from 'shared/hooks/firestore'
import { classesBySchool } from 'shared/firestore/refs'
import { useEffect, useState, useReducer } from 'react'
import { rpc } from 'shared/utils/api'
import flatten from 'lodash/flatten'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'

const useSchoolSurveyData = (
  schoolId,
  districtId,
  filteredGrades,
  hasGradeFilter
) => {
  const [allClasses = [], allClassesLoading] = useFirestoreValues(
    classesBySchool({ id: schoolId, district: districtId })
  )

  const [responses, setResponses] = useState(null)

  const surveyReducer = (state, action) => {
    switch (action.type) {
      case 'reset':
        return {
          school: [],
          selectedGrades: []
        }
      case 'school':
        return {
          school: action.payload,
          selectedGrades: []
        }
      case 'filter':
        return { ...state, selectedGrades: action.payload }
      default:
        return state
    }
  }
  const [surveyData, dispatchSurveyData] = useReducer(surveyReducer, {
    school: [],
    selectedGrades: []
  })
  const [loading, setLoading] = useState(true)

  // when school id changes, reset
  useEffect(() => {
    setResponses(null)
    dispatchSurveyData({ action: 'reset' })
    setLoading(true)
  }, [schoolId])

  // school data
  useEffect(() => {
    if (!allClassesLoading) {
      rpc('motivation.getSchoolSurveyResponses', {
        school: schoolId
      }).then(({ responseData }) => {
        // keep classes and responses within grades 1-6
        const classes = filterClassBySelectedGrade(allClasses)
        const data = responseData.filter(response =>
          classes.includes(response.class)
        )
        setResponses(data)
        //aggregate and format school interest data
        const schoolData = aggregateResults(data)
        dispatchSurveyData({ type: 'school', payload: schoolData })
        setLoading(false)
      })
    }
  }, [allClassesLoading])

  // selected grade data
  useEffect(() => {
    if (!allClassesLoading && responses && hasGradeFilter) {
      const filteredClasses = filterClassBySelectedGrade(
        allClasses,
        filteredGrades
      )

      //aggregate and format selected grade data
      const selectedResponses = responses.filter(response =>
        filteredClasses.includes(response.class)
      )
      const selectedGradesData = aggregateResults(selectedResponses)

      dispatchSurveyData({ type: 'filter', payload: selectedGradesData })
    } else {
      dispatchSurveyData({ type: 'filter', payload: [] })
    }
  }, [filteredGrades, responses, hasGradeFilter])

  return [surveyData, loading]
}

export default useSchoolSurveyData

/**
 * helper function to aggregate the responses
 * Given an array of responses, group the responses by suvery number on question id
 * then pass the results into the aggregateSurveyResults function
 * It will return an array of the formatted data
 * @function aggregateResults
 * @param {Array} responses
 * @returns {Array}
 */
const aggregateResults = responses => {
  const averagesBySurvey = {}

  const responsesBySurvey = groupBy(responses, 'survey_number')

  for (const surveyNumber in responsesBySurvey) {
    const allResults = []
    const currentSurvey = responsesBySurvey[surveyNumber]
    for (let i = 0; i < currentSurvey.length; i++) {
      const results = orderBy(currentSurvey[i].results, 'question_id')
      allResults.push(results)
    }
    const averages = aggregateSurveyResults(flatten(allResults), 'question')
    averagesBySurvey[`survey${surveyNumber}`] = averages
  }
  return formatData(averagesBySurvey)
}

/**
 * helper function to format the data
 * Given an array of averages by survey number, it will format the data
 * such that the return array is an array of objects in the following format:
 * {question, survey1 , survey2 , ...}
 * @function formatData
 * @param {Array} averages
 * @returns {Array}
 */
const formatData = averages => {
  const data = []
  for (const survey in averages) {
    const currentSurvey = averages[survey]
    for (let i = 0; i < currentSurvey.length; i++) {
      const { question, positiveResponses } = currentSurvey[i]
      data[i] = { ...data[i], question, [survey]: positiveResponses }
    }
  }
  return data
}
