import { alphaCompare } from 'shared/utils'

/**
 * @function nameSorter
 * @author Giovanni Bonilla
 * @description util function for sorting names by last name.
 * If last names are identical, sorts by first name.
 * @param {Object} a
 * @param {Object} b
 * @returns {Number}
 */
export default function nameSorter(a, b) {
  const {
    name: { family: aFamily }
  } = a
  const {
    name: { family: bFamily }
  } = b
  const sortKey = aFamily === bFamily ? 'name.given' : 'name.family'

  return alphaCompare(a, b, sortKey)
}
