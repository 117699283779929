import { useParams } from 'react-router-dom'
import useOrgs from 'hooks/useOrgs'
import PropTypes from 'prop-types'
import React from 'react'

import InterestChart from './InterestChart'
import SurveyChart from './SurveyChart'

import './DataMotivation.less'

const DataMotivation = ({ filteredGrades, hasGradeFilter }) => {
  const { schoolId } = useParams()

  const {
    orgs: {
      district: { id: districtId }
    }
  } = useOrgs()

  return (
    <div className='data-motivation'>
      <InterestChart
        schoolId={schoolId}
        districtId={districtId}
        filteredGrades={filteredGrades}
        hasGradeFilter={hasGradeFilter}
      />
      <SurveyChart
        schoolId={schoolId}
        districtId={districtId}
        filteredGrades={filteredGrades}
        hasGradeFilter={hasGradeFilter}
      />
    </div>
  )
}

export default DataMotivation

DataMotivation.propTypes = {
  filteredGrades: PropTypes.object,
  hasGradeFilter: PropTypes.bool.isRequired
}
