/**
 * @file a hook for getting school interest data by class
 * @author Anh Tran
 */

import { useFirestoreValues } from 'shared/hooks/firestore'
import { classesBySchool } from 'shared/firestore/refs'
import { useEffect, useState, useReducer } from 'react'
import { rpc } from 'shared/utils/api'
import { aggregateSurveyResults, filterClassBySelectedGrade } from './utils.js'

/**
 * @function useSchoolInterestData
 * @param {string} schoolId
 * @param {string} districtId
 * @param {Array} filteredGrades
 * @param {Boolean} hasGradeFilter
 * @return {Array}
 */
const useSchoolInterestData = (
  schoolId,
  districtId,
  filteredGrades,
  hasGradeFilter
) => {
  const [allClasses = [], allClassesLoading] = useFirestoreValues(
    classesBySchool({ id: schoolId, district: districtId })
  )

  const [responses, setResponses] = useState(null)

  const interestReducer = (state, action) => {
    switch (action.type) {
      case 'reset':
        return { school: [], selectedGrades: [] }
      case 'school':
        return { school: action.payload, selectedGrades: [] }
      case 'filter':
        return { ...state, selectedGrades: action.payload }
      default:
        return state
    }
  }

  const [interestData, dispatchInterest] = useReducer(interestReducer, {
    school: [],
    selectedGrades: []
  })
  const [loading, setLoading] = useState(true)

  // when school id changes, reset
  useEffect(() => {
    setResponses(null)
    dispatchInterest({ type: 'reset' })
    setLoading(true)
  }, [schoolId])

  // only request school data after all classes finish loading
  useEffect(() => {
    if (!allClassesLoading) {
      rpc('motivation.getAdminInterestData', { school: schoolId }).then(
        ({ responseData }) => {
          // keep classes and responses within grades 1-6
          const classes = filterClassBySelectedGrade(allClasses)
          const responses = responseData.filter(response =>
            classes.includes(response.class)
          )
          setResponses(responses)

          //aggregate and format school interest data
          const schoolData = aggregateSurveyResults(responses, 'date')
          dispatchInterest({ type: 'school', payload: schoolData })

          setLoading(false)
        }
      )
    }
  }, [allClassesLoading])

  // selected grade data
  useEffect(() => {
    if (!allClassesLoading && responses && hasGradeFilter) {
      const filteredClasses = filterClassBySelectedGrade(
        allClasses,
        filteredGrades
      )

      //aggregate and format selected grade data
      const selectedResponses = responses.filter(response =>
        filteredClasses.includes(response.class)
      )
      const selectedGradesData = aggregateSurveyResults(
        selectedResponses,
        'date'
      )

      dispatchInterest({ type: 'filter', payload: selectedGradesData })
    } else {
      dispatchInterest({ type: 'filter', payload: [] })
    }
  }, [filteredGrades, hasGradeFilter, responses])

  return [interestData, loading]
}

export default useSchoolInterestData
