/**
 * @file A component for viewing school interest data
 * @author Anh Tran
 * based on an original skeleton by
 * @author Michael Weintraub
 */

import {
  ResponsiveContainer,
  CartesianGrid,
  LineChart,
  Tooltip,
  Legend,
  XAxis,
  YAxis,
  Line
} from 'recharts'
import useSchoolInterest from './useSchoolInterest'
import PropTypes from 'prop-types'
import palette from '../palette'
import Chart from './Chart'
import { Card } from 'antd'
import moment from 'moment'
import React from 'react'
const chartHeight = 250

const InterestChart = ({
  schoolId,
  districtId,
  filteredGrades,
  hasGradeFilter
}) => {
  const [{ school, selectedGrades }, loading] = useSchoolInterest(
    schoolId,
    districtId,
    filteredGrades,
    hasGradeFilter
  )

  const interestData = hasGradeFilter ? selectedGrades : school

  const emptyMessage =
    hasGradeFilter && school.length > 0
      ? 'No interest data matches the current grade filter.'
      : 'No interest data yet—check back after students complete their first lesson!'

  const renderToolTip = ({ payload, label, active }) => {
    if (active && school.length > 0) {
      const index = school.findIndex(x => x.date === payload[0].payload.date)
      const schoolAverage = Math.floor(school[index].positiveResponses)

      return (
        <div className='custom-tooltip'>
          <h3>{label}</h3>
          <p>{`School Average: ${schoolAverage}%`}</p>
          {hasGradeFilter && (
            <p className='selected-grades'>{`Selected Grades Average: ${Math.floor(
              payload[0].payload.positiveResponses
            )}%`}</p>
          )}
        </div>
      )
    }
    return null
  }

  return (
    <Card title='% of Students Who Like Coding'>
      <ResponsiveContainer width='100%' height={chartHeight}>
        {loading ? (
          <Chart chartHeight={chartHeight} type='loading' />
        ) : interestData.length === 0 ? (
          <Chart
            type='empty'
            chartHeight={chartHeight}
            message={emptyMessage}
          />
        ) : (
          <LineChart data={interestData}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey={({ date }) => moment(date).format('MMM YYYY')} />
            <YAxis dataKey='positiveResponses' unit='%' domain={[0, 100]} />
            <Legend verticalAlign='top' iconType='square' align='right' />
            <Line
              type='linear'
              strokeWidth='2'
              name={hasGradeFilter ? 'Selected Grades' : 'School Averages'}
              dataKey='positiveResponses'
              stroke={
                hasGradeFilter ? palette['@blue-light'] : palette['@gray']
              }
              activeDot={{ r: 8 }}
              dot
            />

            <Tooltip content={renderToolTip} />
          </LineChart>
        )}
      </ResponsiveContainer>
    </Card>
  )
}

export default InterestChart

InterestChart.propTypes = {
  schoolId: PropTypes.string.isRequired,
  districtId: PropTypes.string.isRequired,
  filteredGrades: PropTypes.object,
  hasGradeFilter: PropTypes.bool.isRequired
}
