import {
  pinnedSorter,
  gradeCompare,
  gradesToLabel,
  nameSorter
} from 'shared/utils'
import useSchoolTeacherEngagement from 'hooks/useSchoolTeacherEngagement'
import { Table, Card, Progress, Empty } from 'antd'
import { gradeValueToId } from 'shared/utils/data'
import Loading from 'shared/components/Loading'
import { useParams } from 'react-router-dom'
import React from 'react'

import './DataEngagement.less'

export default ({ filteredGrades, hasGradeFilter }) => {
  const { schoolId } = useParams()

  const [rawEngagementData, loadingEngagementData] = useSchoolTeacherEngagement(
    schoolId
  )

  if (loadingEngagementData) {
    return <Loading />
  }

  if (rawEngagementData && rawEngagementData.length <= 0) {
    return (
      <Empty description='No engagement data yet—check back after teachers have been added to the selected school!' />
    )
  }

  const filteredEngagementData = hasGradeFilter
    ? rawEngagementData.filter(({ grades }) =>
        grades.some(grade => filteredGrades[gradeValueToId(grade)])
      )
    : rawEngagementData

  if (filteredEngagementData.length <= 0) {
    return (
      <Empty description='No engagement data matches the current grade filter.' />
    )
  }

  const engagementDataWithAllClasses = [
    getEngagementAggregate(filteredEngagementData),
    ...filteredEngagementData
  ]

  return (
    <div className='data-engagement'>
      <Card>
        <Table
          columns={columns}
          dataSource={engagementDataWithAllClasses}
          rowKey={(_, i) => i}
          pagination={false}
        />
      </Card>
    </div>
  )
}

const columns = [
  {
    title: 'Teachers',
    key: 'name',
    sorter: pinnedSorter(nameSorter, 'id'),
    render: ({ name = {} }) => {
      // Don't append comma to 'All Teachers' display name or to emails
      if (name.family === 'All Teachers' || !name.given) return name.family
      return `${name.family}, ${name.given}`
    },
    defaultSortOrder: 'ascend'
  },
  {
    title: 'Grade',
    key: 'grades',
    sorter: pinnedSorter(gradeCompare, 'id'),
    render: ({ grades }) => gradesToLabel(grades),
    align: 'center'
  },
  {
    title: '# of Lessons Taught',
    dataIndex: 'lessonsTaught',
    sorter: pinnedSorter((a, b) => a.lessonsTaught - b.lessonsTaught, 'id'),
    align: 'center'
  },
  {
    title: 'Avg. Usage Time Per Week',
    key: 'avgUsageTime',
    sorter: pinnedSorter((a, b) => a.avgUsageTime - b.avgUsageTime, 'id'),
    render: ({ avgUsageTime }) => {
      return <span>{Number(avgUsageTime / 60).toFixed()} mins</span>
    },
    align: 'center'
  },
  {
    title: '% of Certification Modules Completed',
    key: 'PD Completion %',
    sorter: pinnedSorter(pdCompletionCompare, 'id'),
    render: ({ completedPdActivities, totalPdActivities }) => {
      const percentCompleted = +Number(
        (completedPdActivities / totalPdActivities) * 100
      ).toFixed()
      return isNaN(percentCompleted) ? (
        'No Assigned PD Modules'
      ) : (
        <span>
          <Progress strokeLinecap='square' percent={percentCompleted} />
        </span>
      )
    },
    align: 'center'
  }
]

/**
 * Given an array of engagement data, returns an object aggregating all the given data,
 * in a format that matches an engagement data object (with some exclusions that are not
 * required for displaying the data in this component).
 * @function getEngagementAggregate
 * @param {Array.<Object>} teacherEngagementData
 * @returns {Object}
 */
function getEngagementAggregate(teacherEngagementData) {
  const aggregateLessonsTaught = getPropertyAggregate(
    teacherEngagementData,
    'lessonsTaught'
  )

  const aggregateAvgUsageTime =
    getPropertyAggregate(teacherEngagementData, 'avgUsageTime') /
    teacherEngagementData.length

  const aggregateTotalPdActivities = getPropertyAggregate(
    teacherEngagementData,
    'totalPdActivities'
  )

  const aggregateCompletedPdActivities = getPropertyAggregate(
    teacherEngagementData,
    'completedPdActivities'
  )

  return {
    id: '',
    name: {
      family: 'All Teachers',
      given: ''
    },
    grades: [],
    lessonsTaught: aggregateLessonsTaught,
    avgUsageTime: aggregateAvgUsageTime,
    totalPdActivities: aggregateTotalPdActivities,
    completedPdActivities: aggregateCompletedPdActivities
  }
}

/**
 * @function pdCompletionCompare
 * @description A function for sorting teachers by pd completion percentage. Teacher's whose
 * PD completion percentage is infinity are sorted to the top regardless of sort order.
 * @param {Object} a
 * @param {Object} b
 * @param {String} sortOrder
 * @returns {Number}
 */
function pdCompletionCompare(a, b, sortOrder) {
  const aPercentCompleted =
    (a.completedPdActivities / a.totalPdActivities) * 100
  const bPercentCompleted =
    (b.completedPdActivities / b.totalPdActivities) * 100
  if (isFinite(aPercentCompleted) && isFinite(bPercentCompleted)) {
    return aPercentCompleted - bPercentCompleted
  } else if (sortOrder === 'ascend') {
    return !isFinite(aPercentCompleted) ? -1 : 1
  } else {
    return !isFinite(aPercentCompleted) ? 1 : -1
  }
}

/**
 * @function getPropertyAggregate
 * @description Given an array of teacher engagement data and a property,
 * calculates the aggregate value for the given property
 * @param {Array.<Object>} teacherEngagementData
 * @param {String} property
 * @returns {Number}
 */
function getPropertyAggregate(teacherEngagementData, property) {
  return teacherEngagementData.reduce(
    (total, curr) => total + curr[property],
    0
  )
}
