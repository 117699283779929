import { grades as allGrades } from 'shared/utils/data'
import SchoolPicker from '../../People/SchoolPicker'
import { useParams } from 'react-router-dom'
import useOrgs from 'hooks/useOrgs/useOrgs'
import { Col, Row, Tag } from 'antd'
import React from 'react'

import './DataContext.less'

export default ({
  path,
  toggleFilteredGrade,
  filteredGrades,
  resetFilteredGrades,
  hasGradeFilter
}) => {
  const { schoolId, category } = useParams()
  const {
    orgs: { schools = [] }
  } = useOrgs()
  const currentSchool = schools.find(({ id }) => id === schoolId)
  const studentGrades = allGrades.filter(({ id }) => (-1 <= id && id <= 6))

  return (
    <Row
      className='data-context'
      justify='space-between'
      align='middle'
      type='flex'
    >
      <Col>
        <div className='school-picker'>
          <SchoolPicker
            currentSchool={currentSchool}
            schools={schools}
            path={path}
          />
        </div>
      </Col>
      {category !== 'overview' && (
        <Col>
          <h4>
            Filter By Grade:
            {hasGradeFilter && (
              <Tag onClick={resetFilteredGrades} className='clear-all'>
                Clear
              </Tag>
            )}
          </h4>
          {studentGrades.map(grade => (
            <Tag.CheckableTag
              className='grade-filter-tag'
              key={grade.value}
              checked={filteredGrades[grade.id]}
              onChange={() => toggleFilteredGrade(grade.id)}
            >
              {grade.label}
            </Tag.CheckableTag>
          ))}
        </Col>
      )}
    </Row>
  )
}
