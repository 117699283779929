import { Menu, Icon, Dropdown, Row, Col, Avatar, Divider, message } from 'antd'
import DistrictSwitcherModal from 'components/DistrictSwitcherModal'
import isNinedotsRole from 'shared/utils/isNinedotsRole'
import adminAnalyticsTracker from 'app/Data/analytics'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { logout } from 'shared/ducks/user/actions'
import { useModal } from 'shared/hooks/useModal'
import styles from 'shared/theme/vars/vars.js'
import { adminRoles } from 'shared/utils/data'
import React, { useCallback } from 'react'
import { config } from 'configureStore'
import { setUrl } from 'shared/actions'
import { rpc } from 'shared/utils/api'

import './Sidebar.less'

const menuPadding = 10
const { clientUrl } = config
const Sidebar = () => {
  const dispatch = useDispatch()
  const profile = useSelector(state => state.firebase.profile || {})
  const setDistrict = useCallback(district => {
    try {
      return rpc('user.setDistrict', { district })
    } catch (e) {
      message.error(e.error.message, 3000)
    }
  })
  const [showDistrictSwitcher, hideDistrictSwitcher] = useModal(
    <DistrictSwitcherModal
      currentDistrict={profile.district}
      onOk={district =>
        setDistrict(district)
          .then(hideDistrictSwitcher)
          .then(() => dispatch(setUrl('/')))
      } />
  )
  const menuClickHandler = useCallback(e => {
    switch (e.key) {
      case 'switchDistrict':
        return showDistrictSwitcher()
      case 'logout':
        return dispatch(logout())
      default:
        break
    }
  })
  const { rootPath } = useParams()
  const { displayName, role } = profile
  const isAdmin = adminRoles.includes(role)
  const isNinedots = isNinedotsRole(role)
  const trackerSource = 'Admin Sidebar'
  const analyticsTracker = adminAnalyticsTracker(trackerSource)
  return (
    <>
      <div style={{ padding: `${menuPadding}px 20px` }}>
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu onClick={menuClickHandler} className='user-menu'>
              {isNinedots && (
                <Menu.Item key='switchDistrict'>
                  <Icon type='swap' style={{ marginRight: 10 }} />
                  Switch District
                </Menu.Item>
              )}
              <Menu.Item key='logout'>
                <Icon type='logout' style={{ marginRight: 10 }} />
                Log Out
              </Menu.Item>
            </Menu>
          }
        >
          <Row
            type='flex'
            align='middle'
            style={{
              height: styles['@layout-header-height'] - menuPadding * 2 + 'px',
              cursor: 'pointer'
            }}
          >
            <Col>
              <Avatar icon='user' />
            </Col>
            <Col className='flex-grow' style={{ padding: '0 10px' }}>
              {displayName}
            </Col>
            <Col>
              <Icon type='caret-down' style={{ fontSize: 9 }} />
            </Col>
          </Row>
        </Dropdown>
      </div>
      <Divider />
      <Menu
        selectedKeys={[rootPath || '/']}
        theme='dark'
        mode='inline'
        style={{ borderRight: 0 }}
      >
        {isAdmin && (
          <Menu.Item key='/'>
            <Link to='/'>
              <Icon type='setting' />
              Account
            </Link>
          </Menu.Item>
        )}
        {(role === 'districtAdmin' || role === 'ninedotsAdmin') && (
          <Menu.Item key='schools'>
            <Link to='/schools'>
              <Icon type='book' />
              Schools
            </Link>
          </Menu.Item>
        )}
        {isAdmin && (
          <Menu.Item key='people'>
            <Link to='/people'>
              <Icon type='user' />
              People
            </Link>
          </Menu.Item>
        )}
        {/* <Menu.Item key='analytics'>
          <Link to='/analytics'>
            <Icon type='line-chart' />
            Analytics
          </Link>
        </Menu.Item> */}
        <Menu.Item key='data'>
          <Link
            to='/data'
            onClick={analyticsTracker.overview}
          >
            <Icon type='line-chart' />
            {'Data'}
          </Link>
        </Menu.Item>
        {isNinedots && (
          <Menu.Item key='independentTeachers'>
            <Link to='/independent-teachers'>
              <Icon type='line-chart' />
              {'Level 3 Teachers'}
            </Link>
          </Menu.Item>
        )}
        {((profile.schools || []).length || isNinedots) && (
          <Menu.Item key='teach'>
            <a href={clientUrl} rel='noopener noreferrer' target='_blank'>
              <Icon type='export' />
              Teach
            </a>
          </Menu.Item>
        )}
        <Menu.Divider />
      </Menu>
    </>
  )
}

Sidebar.propTypes = {}

export default Sidebar
