import { gradeValueToId } from 'shared/utils/data'
import { decimalToPercent } from 'shared/utils'
import groupBy from 'lodash/groupBy'

/**
 * Given an array of classes and an object of selected grades,
 * only classes within selectedGrades are kept
 * If selectedGrades is not provided then only classes in grades
 * 1-6 are kept.
 * Returns an array of class ids
 * @function filterClassBySelectedGrade
 * @param {Array} classes
 * @returns {Array}
 */

export const filterClassBySelectedGrade = (classes, selectedGrades) =>
  classes
    .filter(c => {
      const grade = gradeValueToId(c.grade)
      return selectedGrades ? selectedGrades[grade] : grade >= 1 && grade <= 6
    })
    .map(c => c.id)

/**
 * Responses will be group together according to the iteratee, then
 * the grouped responses will be aggregrated to find the averages for each group
 * The return array will be an array of objects of the iteratee information
 * and the positive responses average.
 * @function aggregrateSurveyResults
 * @param {Array} responses
 * @param {String} iteratee
 * @returns {Array}
 */

export const aggregateSurveyResults = (responses, iteratee) => {
  const getAverage = data =>
    decimalToPercent(data.totalPositiveResponses / data.totalResponses)

  const groupedResponses = groupBy(responses, iteratee)

  const average = []

  const keys = Object.keys(groupedResponses)

  for (let i = 0; i < keys.length; i++) {
    const totals = groupedResponses[keys[i]].reduce(
      (acc, cur) => {
        return {
          totalResponses: acc.totalResponses + parseInt(cur.totalResponses),
          totalPositiveResponses:
            acc.totalPositiveResponses + parseInt(cur.totalPositiveResponses)
        }
      },
      {
        totalResponses: 0,
        totalPositiveResponses: 0
      }
    )

    average.push({
      [iteratee]: keys[i],
      positiveResponses: getAverage(totals)
    })
  }
  return average
}
