/**
 * @file A component for viewing school's survey data
 * @author Anh Tran
 * based on an original skeleton by
 * @author Michael Weintraub
 */

import {
  ResponsiveContainer,
  CartesianGrid,
  BarChart,
  Tooltip,
  Legend,
  XAxis,
  YAxis,
  Text,
  Bar
} from 'recharts'
import useSchoolSurveyData from './useSchoolSurveyData'
import palette from 'shared/theme/vars/palette'
import { Card, Row, Col } from 'antd'
import PropTypes from 'prop-types'
import Chart from './Chart'
import React from 'react'
import { gradesToText } from 'shared/utils/data'

const chartHeight = 500

const SurveyChart = ({
  schoolId,
  districtId,
  filteredGrades,
  hasGradeFilter
}) => {
  const [{ school, selectedGrades }, loading] = useSchoolSurveyData(
    schoolId,
    districtId,
    filteredGrades,
    hasGradeFilter
  )

  const interestData = hasGradeFilter ? selectedGrades : school

  const createEmptyFilterMessage = () => {
    const keys = Object.keys(filteredGrades)

    if (keys.some(grade => grade > 1)) {
      return 'No survey responses match the current grade filter.'
    } else {
      if (keys.length > 1) {
        return 'No data—selected grades do not complete surveys.'
      } else {
        return `No data—${gradesToText(
          keys[0],
          true
        )} does not complete surveys.`
      }
    }
  }

  const emptyMessage =
    school.length === 0
      ? 'No survey responses yet—check back after students complete their first survey!'
      : hasGradeFilter && createEmptyFilterMessage()

  const renderCustomTooltip = ({ active, payload, label }) => {
    const formatter = num => Math.floor(num) + '%'

    if (active) {
      return (
        <div className='custom-tooltip'>
          <h3>{label}</h3>
          <Row type='flex' key='header' gutter={[16, 8]}>
            <Col key='school' span={12} >
              School Averages:
            </Col>
            {hasGradeFilter && (
              <Col key='filter' span={12} className='selected-grades'>
                Selected Grades:
              </Col>
            )}
          </Row>
          {payload.map(({ dataKey, name, color, value, payload }, i) => {
            const { question } = payload
            const index = school.findIndex(x => x.question === question)

            return (
              <Row type='flex' key={i} gutter={[16, 8]}>
                <Col style={{ color }} key={i} span={12}>
                  {`${name}: ${formatter(school[index][dataKey])}`}
                </Col>
                {hasGradeFilter ? (
                  <Col style={{ color }} key={`filter-${i}`} span={12}>
                    {`${name}: ${formatter(value)}`}
                  </Col>
                ) : null}
              </Row>
            )
          })}
        </div>
      )
    }
    return null
  }

  return (
    <Card title='Student Survey Responses'>
      <ResponsiveContainer width='100%' height={500}>
        {loading ? (
          <Chart chartHeight={chartHeight} type='loading' />
        ) : interestData.length === 0 ? (
          <Chart
            type='empty'
            chartHeight={chartHeight}
            message={emptyMessage}
          />
        ) : (
          <BarChart
            data={interestData}
            margin={{ top: 20, bottom: 50, left: 10 }}
          >
            <Legend align='right' verticalAlign='top' />
            <Tooltip
              content={renderCustomTooltip}
              cursor={{fill: '#f0f0f0'}}
              // formatter={(value, name, props) => Math.floor(value) + '%'}
            />
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
              dataKey='question'
              tick={renderCustomXAxisTick}
              interval={0}
            />
            <YAxis dataKey='survey1' unit='%' domain={[0, 100]} />
            <Bar dataKey='survey1' name='Survey 1' fill={palette['@blue-1']} />
            {interestData[0].survey2 && (
              <Bar dataKey='survey2' name='Survey 2'fill={palette['@blue-2']} />
            )}
            {interestData[0].survey3 && (
              <Bar dataKey='survey3' name='Survey 3' fill={palette['@blue-3']} />
            )}
            {interestData[0].survey4 && (
              <Bar dataKey='survey4' name='Survey 4' fill={palette['@blue-4']} />
            )}
          </BarChart>
        )}
      </ResponsiveContainer>
    </Card>
  )
}

export default SurveyChart

SurveyChart.propTypes = {
  schoolId: PropTypes.string.isRequired,
  districtId: PropTypes.string.isRequired,
  filteredGrades: PropTypes.object,
  hasGradeFilter: PropTypes.bool.isRequired
}

const renderCustomXAxisTick = ({ x, y, payload }) => {
  return (
    <Text
      x={x}
      y={y}
      textAnchor='middle'
      verticalAnchor='start'
      width={100}
      dy={5}
    >
      {payload.value}
    </Text>
  )
}
