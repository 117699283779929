/**
 * @file A component for viewing school proficiency data
 * @author Julius Diaz Panoriñgan
 * based on an original skeleton by
 * @author Michael Weintraub
 * @todo deprecate use of useSchoolProficiencyOverview hook
 */

import useSchoolProficiencyOverview from 'hooks/useSchoolProficiencyOverview'
import useSchoolProficiency from 'hooks/useSchoolProficiency'
import { gradeValueToShortText } from 'shared/utils/data'
import { Card, Empty, Icon, Table, Tooltip } from 'antd'
import ProficiencyBar from 'components/ProficiencyBar'
import palette from 'shared/theme/vars/palette'
import Loading from 'shared/components/Loading'
import { useParams } from 'react-router-dom'
import { alphaCompare } from 'shared/utils'
import useOrgs from 'hooks/useOrgs'
import PropTypes from 'prop-types'
import React from 'react'

import './DataProficiency.less'

const DataProficiency = ({ filteredGrades, hasGradeFilter }) => {
  const { schoolId } = useParams()
  const {
    orgs: {
      district: { id: districtId }
    }
  } = useOrgs()
  const [rawProficiencyData, loading] = useSchoolProficiency(
    schoolId,
    districtId
  )

  const [schoolwideData, schoolwideDataLoading] = useSchoolProficiencyOverview(
    schoolId
  )

  // render loading screen if loading
  if (loading || schoolwideDataLoading) {
    return <Loading />
  }

  const rawProficiencyCounts = generateProficiencyCounts(rawProficiencyData)

  // render check back message if no raw proficiency data
  if (rawProficiencyCounts.every(count => count === 0)) {
    return (
      <Empty description='No proficiency data yet—check back after students complete their first lesson!' />
    )
  }

  const filteredProficiencyData = hasGradeFilter
    ? rawProficiencyData.filter(({ gradeNum }) => filteredGrades[gradeNum])
    : rawProficiencyData
  const filteredProficiencyCounts = hasGradeFilter
    ? generateProficiencyCounts(filteredProficiencyData)
    : generateProficiencyCounts([schoolwideData]) // : rawProficiencyCounts

  // render helpful message if all data filtered out
  if (filteredProficiencyCounts.every(count => count === 0)) {
    return (
      <Empty description='No proficiency data matches the current grade filter.' />
    )
  }

  const proficiencyDataWithAllClasses = [
    generateProficiencyAggregate(
      filteredProficiencyData,
      filteredProficiencyCounts
    ),
    ...filteredProficiencyData
  ]

  return (
    <>
      <ProficiencyBar proficiencyCounts={filteredProficiencyCounts} />
      <Card className='proficiency-card'>
        <Table
          columns={columns}
          dataSource={proficiencyDataWithAllClasses}
          pagination={false}
          rowKey={c => c.classId}
        />
      </Card>
    </>
  )
}

DataProficiency.propTypes = {
  filteredGrades: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.bool))
    .isRequired,
  hasGradeFilter: PropTypes.bool.isRequired
}

export default DataProficiency

const columns = [
  {
    title: 'Classes',
    dataIndex: 'displayName',
    sorter: pinnedSorter((a, b) => alphaCompare(a, b, 'displayName')),
    defaultSortOrder: 'ascend'
  },
  {
    title: 'Grade',
    dataIndex: 'gradeText',
    render: gradeText => {
      if (gradeText !== '—') gradeText = gradeValueToShortText(gradeText)
      return <span>{gradeText}</span>
    },
    sorter: (a, b) => a.gradeNum - b.gradeNum, // pinnedSorter not required!
    align: 'center'
  },
  {
    title: '# of Lessons Taught',
    dataIndex: 'numLessons',
    sorter: pinnedSorter((a, b) => a.numLessons - b.numLessons),
    align: 'center'
  },
  {
    title: (
      <Tooltip title='Represents the average amount of time that students spend working individually on computers during class.'>
        {'Avg. Practice Time Per Lesson '}
        <Icon type='question-circle' />
      </Tooltip>
    ),
    dataIndex: 'avgPracticeTime',
    render: avgPracticeTime => (
      <span>{Number(avgPracticeTime / 60000).toFixed(1)} min</span>
    ),
    sorter: pinnedSorter((a, b) => a.avgPracticeTime - b.avgPracticeTime),
    align: 'center'
  },
  {
    title: 'Avg. Proficiency',
    dataIndex: 'avgProficiency',
    render: avgProficiency => (
      <span style={{ color: proficiencyToColor(avgProficiency) }}>
        {avgProficiency ? Number(avgProficiency).toFixed(1) : 'No data'}
      </span>
    ),
    sorter: pinnedSorter((a, b) => a.avgProficiency - b.avgProficiency),
    align: 'center'
  }
]

/**
 * Given an array of proficiency data, returns a four-element array with the
 * counts of students at proficiency level 1, 2, 3, and 4.
 * @function generateProficiencyCounts
 * @author Julius Diaz Panoriñgan
 * @param {Array} proficiencyData
 * @returns {Array}
 */
function generateProficiencyCounts(proficiencyData) {
  return proficiencyData
    ? proficiencyData.reduce(
        (counts, { p1, p2, p3, p4 }) => {
          counts[0] += p1
          counts[1] += p2
          counts[2] += p3
          counts[3] += p4
          return counts
        },
        [0, 0, 0, 0]
      )
    : [0, 0, 0, 0]
}

/**
 * Given an array of proficiency data and an array of proficiency counts,
 * returns an object aggregating all the given data, in a format that matches
 * a proficiency data object (with some exclusions that are not required for
 * displaying the data in this component).
 * @function generateProficiencyAggregate
 * @author Julius Diaz Panoriñgan
 * @param {Array} proficiencyData
 * @param {Array} proficiencyCounts
 * @returns {Array}
 */
function generateProficiencyAggregate(proficiencyData, proficiencyCounts) {
  const numLessons = proficiencyData.reduce(
    (total, { numLessons }) => total + numLessons,
    0
  )

  const totalTime = proficiencyData.reduce(
    (total, { avgPracticeTime, numLessons }) =>
      total + avgPracticeTime * numLessons,
    0
  )

  const numStudents = proficiencyCounts.reduce((total, count) => total + count)

  const proficiencySum = proficiencyCounts.reduce(
    (sum, count, i) => sum + count * (i + 1)
  )

  return {
    classId: '',
    displayName: 'All Classes',
    gradeText: '—',
    numLessons,
    avgPracticeTime: totalTime / numLessons,
    avgProficiency: proficiencySum / numStudents
  }
}

/**
 * @todo Created a general pinnedSorter function which currently lives in the shared/utils dir.
 * Delete this pinnedSorter and use the one from shared/utils instead.
 */

/**
 * Given a standard sort function (a, b) => number, returns a function that
 * takes a, b, and a sortOrder, which can be used as a sorter function for an
 * ant design table. This returned  function works like the given sort function,
 * except it always pins an object without a classId property at the top of the
 * sort, no matter the sort order.
 * @function pinnedSorter
 * @author Julius Diaz Panoriñgan
 * @param {Function} sorter
 * @returns {Function}
 */
function pinnedSorter(sorter) {
  return (a, b, sortOrder) => {
    if (a.classId && b.classId) {
      return sorter(a, b)
    } else if (sortOrder === 'ascend') {
      return a.classId ? Infinity : -Infinity
    } else {
      // sortOrder === 'descend'
      return a.classId ? -Infinity : Infinity
    }
  }
}

/**
 * Given a proficiency, returns a color string.
 * Returns undefined if proficiency === 0.
 * @function proficiencyToColor
 * @author Julius Diaz Panoriñgan
 * @param {number} proficiency
 * @returns {string}
 */
function proficiencyToColor(proficiency) {
  if (proficiency === 0) {
    return
  } else if (proficiency < 1.5) {
    return palette['@red']
  } else if (proficiency < 2.5) {
    return palette['@yellow']
  } else if (proficiency < 3.5) {
    return palette['@green']
  } else {
    // 3.5 <= proficiency <= 4.0
    return palette['@blue-light']
  }
}
