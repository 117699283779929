/**
 * @file a hook for getting teacher engagement data by school
 * @author Giovanni Bonilla
 */
import { useEffect, useState } from 'react'
import { rpc } from 'shared/utils/api'

/**
 * @function useSchoolTeacherEngagement
 * @param {string} schoolId
 * @returns {Array}
 */
const useSchoolTeacherEngagement = (schoolId) => {
  const [teacherEngagementData, setTeacherEngagementData] = useState(null)
  const [loadingData, setLoadingData] = useState(true)

  useEffect(() => {
    setTeacherEngagementData(null)
    setLoadingData(true)
  }, [schoolId])

  useEffect(() => {
    rpc('school.getTeacherEngagement', {
      school: schoolId
    }).then(({ teacherEngagementData }) => {
      setTeacherEngagementData(teacherEngagementData)
      setLoadingData(false)
    })
  }, [schoolId])

  return [teacherEngagementData, loadingData]
}

export default useSchoolTeacherEngagement
