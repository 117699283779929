import useGitDistrictsAndSchools from 'hooks/useGitDistrictsAndSchools'
import GitEngagementTable from 'components/GitEngagementTable'
import HeaderLayout from 'components/HeaderLayout'
import { Card, Table, Tag, Divider, Button, message } from 'antd'
import Loading from 'shared/components/Loading'
import Header from 'shared/components/Header'
import { alphaCompare } from 'shared/utils'
import React, { useState } from 'react'
import { rpc } from 'shared/utils/api'
import './IndependentTeachers.less'

const IndependentTeachers = () => {
  const combineData = []
  const [{ districtData, schoolData }, loading] = useGitDistrictsAndSchools()
  const [filter, setFilter] = useState({})
  const [gitData, setGitData] = useState([])
  const [loadingGitData, setLoadingGitData] = useState(false)

  /** @todo refactor to memoize/cache school's GIT engagement data */
  const handleGetData = async (selectedSchools) => {
    setLoadingGitData(true)
    try{
      const { ok, data } = await rpc('school.getSelectedSchoolsGitEngagement', { schools: selectedSchools })
      if (ok) {
        setGitData(data)
        setLoadingGitData(false)
      }
    } catch (e) {
      message.error(`Oops! Can't get data. Error: ${e.error}`)
    }
  }

  for (const data of gitData) {
    const { gitEngagementData } = data
    for (const teacherData of gitEngagementData) {
      const { school, district } = teacherData
      combineData.push({
        ...teacherData,
        schoolName: schoolData[school].displayName,
        districtName: districtData[district].displayName
      })
    }
  }

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <HeaderLayout header={<Header title='Independent Teachers' />}>
        <Filter
          districtData={districtData}
          schoolData={schoolData}
          setFilter={setFilter}
          filter={filter}
        />
        <Button
          onClick={() => handleGetData(getSelectedSchools(filter, schoolData))}
          disabled={!Object.values(filter).includes(true)}
        >
          Get Data
        </Button>
        {loadingGitData ? (
          <Loading/>
        ) : (
          !!gitData.length && (
            <>
              <Summary schoolData={gitData} />
              <GitEngagementTable data={combineData} showOrgs />
            </>
          )
        )}
      </HeaderLayout>
    </>
  )
}

export default IndependentTeachers

const getSelectedSchools = (filter, schoolData) => {
  const selectedSchools = {}
  for (const schoolId in filter) {
    if(filter[schoolId]) {
      const { districtId } = schoolData[schoolId]
      if (selectedSchools[districtId]) {
        selectedSchools[districtId].push(schoolId)
      } else {
        selectedSchools[districtId] = [schoolId]
      }
    }
  }
  return selectedSchools
}

const Summary = ({ schoolData }) => {
  let numSchools = 0
  let numTeachers = 0
  let totalLessonsTaught = 0
  const numMeetingBenchmarks = [0, 0, 0, 0]
  for (const schoolId in schoolData) {
    numSchools++
    const teacherLessonsTaught = {}
    for (const teacherClassData of schoolData[schoolId].gitEngagementData) {
      const { lessonsTaught, id: teacherId } = teacherClassData
      teacherLessonsTaught[teacherId] = Math.max(
        teacherLessonsTaught[teacherId] || 0,
        lessonsTaught
      )
    }
    for (const lessonsTaught of Object.values(teacherLessonsTaught)) {
      numTeachers++
      totalLessonsTaught += lessonsTaught
      if (lessonsTaught >= 30) {
        numMeetingBenchmarks[3]++
      } else if (lessonsTaught >= 20) {
        numMeetingBenchmarks[2]++
      } else if (lessonsTaught >= 10) {
        numMeetingBenchmarks[1]++
      } else {
        numMeetingBenchmarks[0]++
      }
    }
  }
  const columns = [
    { title: 'Modules Taught', dataIndex: 'modulesTaught', width: '50%' },
    { title: '0', dataIndex: 'zero', align: 'center' },
    { title: '1', dataIndex: 'one', align: 'center' },
    { title: '2', dataIndex: 'two', align: 'center' },
    { title: '3', dataIndex: 'three', align: 'center' }
  ]
  const data = [
    {
      key: '1',
      modulesTaught: 'Teachers',
      zero: numMeetingBenchmarks[0],
      one: numMeetingBenchmarks[1],
      two: numMeetingBenchmarks[2],
      three: numMeetingBenchmarks[3]
    }
  ]
  return (
    <Card className='gits-summary' size='small'>
      <h3>Summary</h3>
      <div className='gits-summary-content'>
        <h4>
          At <b>{numSchools}</b> schools, <b>{numTeachers}</b> teachers have
          taught{' '}
          <b>{Number(totalLessonsTaught / numTeachers || 0).toFixed(1)}</b>{' '}
          lessons on average.
        </h4>
        <Table
          className='gits-summary-table'
          bordered
          pagination={false}
          columns={columns}
          dataSource={data}
          size='small'
        />
      </div>
    </Card>
  )
}

const Filter = ({ districtData, schoolData, setFilter, filter }) => {
  const toggleAll = isSelected => {
    setFilter(oldFilter => {
      const newFilter = { ...oldFilter }
      for (const schoolId in schoolData) {
        newFilter[schoolId] = isSelected
      }
      return newFilter
    })
  }

  const toggleSchool = schoolId => {
    setFilter(oldFilter => {
      const newFilter = { ...oldFilter }
      newFilter[schoolId] = !oldFilter[schoolId]
      return newFilter
    })
  }

  const toggleDistrict = (districtId, isSelected) => {
    setFilter(oldFilter => {
      const { schools } = districtData[districtId]
      const newFilter = { ...oldFilter }
      for (const school of schools) {
        newFilter[school.id] = isSelected
      }
      return newFilter
    })
  }
  return (
    <>
      <h4>
        <b> Filter By School</b>
      </h4>
      <div>
        <Tag
          onClick={() => {
            toggleAll(true)
          }}
        >
          Select All Schools
        </Tag>
        <Tag
          onClick={() => {
            toggleAll(false)
          }}
        >
          Clear Selection
        </Tag>
      </div>
      <Divider />
      {Object.entries(districtData)
        .sort((a, b) => alphaCompare(a[1], b[1], 'displayName'))
        .map(([districtId, district]) => {
          return (
            <div key={districtId}>
              <h4>
                <b>{district.displayName}</b>
                &emsp;
                <Tag
                  onClick={() => {
                    toggleDistrict(districtId, true)
                  }}
                >
                  Select All
                </Tag>
                <Tag
                  onClick={() => {
                    toggleDistrict(districtId, false)
                  }}
                >
                  Clear All
                </Tag>
              </h4>
              {district.schools
                .sort((a, b) => alphaCompare(a, b, 'displayName'))
                .map(school => {
                  return (
                    <Tag.CheckableTag
                      key={school.id}
                      checked={filter[school.id]}
                      onChange={() => {
                        toggleSchool(school.id)
                      }}
                    >
                      {school.displayName}
                    </Tag.CheckableTag>
                  )
                })
              }
              <Divider />
            </div>
          )
      })}
    </>
  )
}
