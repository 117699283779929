/**
 * @file a hook for getting school overview data
 * @author Julius Diaz Panoriñgan
 */

import numbro from 'numbro'
import { useEffect, useState } from 'react'

import { rpc } from 'shared/utils/api'

const useSchoolOverview = schoolId => {
  const [overviewData, setOverviewData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    setOverviewData(null)
    rpc('school.getOverviewData', { school: schoolId }).then(data => {
      setOverviewData({
        proficiencyCounts: processProficiencyCounts(data.proficiencyCounts),
        percentLikingCoding: formatPercent(data.percentLikingCoding),
        challengesCompleted: formatShortScale(data.challengesCompleted),
        codingTime: formatShortScale(data.codingTime),
        randomRecentLesson: data.randomRecentLesson
      })
      setLoading(false)
    })
  }, [schoolId])

  return [overviewData, loading]
}

/**
 * Given a proficiency counts object, returns an array of proficiency counts.
 * If there are no students with proficiency data, returns null instead.
 * @param {!Object.<?string>} rawProficiencyCounts
 * @returns {?Array.<number>}
 */
const processProficiencyCounts = rawProficiencyCounts => {
  const { p1, p2, p3, p4 } = rawProficiencyCounts
  const proficiencyCounts = [p1, p2, p3, p4].map(count =>
    count === null ? 0 : parseInt(count, 10)
  )
  return proficiencyCounts.some(count => count > 0) ? proficiencyCounts : null
}

/**
 * Formats a percent to have no decimal point.
 * If passed null, returns null.
 * @function formatPercent
 * @param {?number} rawPercent
 * @returns {?string}
 */
const formatPercent = rawPercent =>
  rawPercent && numbro(rawPercent).format({ output: 'percent', mantissa: 0 })

/**
 * Abbreviates a number according to the short scale naming system.
 * https://en.wikipedia.org/wiki/Long_and_short_scales
 * http://numbrojs.com/format.html#average
 * @function formatShortScale
 * @param {!number} num
 * @returns {string}
 */
const formatShortScale = num =>
  numbro(num)
    .format({ average: true })
    .toUpperCase()

export default useSchoolOverview
