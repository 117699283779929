/**
 * @file a hook for getting school proficiency overview data; to be deprecated
 * @author Julius Diaz Panoriñgan
 * @todo deprecate
 */

import { useEffect, useState } from 'react'
import { rpc } from 'shared/utils/api'

const proficiencyLevels = ['p1', 'p2', 'p3', 'p4']

const useSchoolProficiencyOverview = schoolId => {
  const [schoolwideData, setSchoolwideData] = useState(null)

  useEffect(() => {
    setSchoolwideData(null)
    rpc('school.getProficiencyOverview', { school: schoolId }).then(
      ({ proficiencyCounts }) => {
        proficiencyLevels.forEach(level => {
          proficiencyCounts[level] = parseInt(proficiencyCounts[level], 10)
        })
        setSchoolwideData(proficiencyCounts)
      }
    )
  }, [schoolId])

  return [schoolwideData, schoolwideData === null]
}

export default useSchoolProficiencyOverview
