/**
 * Given a standard sort function (a, b) => number and a property, returns a function that
 * takes a, b, and a sortOrder, which can be used as a sorter function for an
 * ant design table. This returned function works like the given sort function,
 * except it always pins an object without the given property at the top of the
 * sort, no matter the sort order. Note: Function assumes that there will only be
 * one object with the missing property. May therefore produce unpredicatable behavior
 * if there multiple objects with out the given property .
 * @function pinnedSorter
 * @author Julius Diaz Panoriñgan
 * @author Giovanni Bonilla
 * @param {Function} sorter
 * @param {String} property
 * @returns {Function}
 */
export default function pinnedSorter(sorter, property) {
  return (a, b, sortOrder) => {
    if (a[property] && b[property]) {
      return sorter(a, b, sortOrder)
    } else if (sortOrder === 'ascend') {
      return a[property] ? Infinity : -Infinity
    } else {
      // sortOrder === 'descend'
      return a[property] ? -Infinity : Infinity
    }
  }
}
