import {
  Switch,
  Route,
  Redirect,
  useParams
} from 'react-router-dom'
import React, { useCallback, useReducer, useEffect } from 'react'
import DataGitEngagement from './DataGitEngagement'
import HeaderLayout from 'components/HeaderLayout'
import adminAnalyticsTracker from './analytics'
import DataProficiency from './DataProficiency'
import Header from 'shared/components/Header'
import DataEngagement from './DataEngagement'
import DataMotivation from './DataMotivation'
import DataOverview from './DataOverview'
import DataContext from './DataContext'
import useOrgs from 'hooks/useOrgs'
import './Data.less'

const Data = () => {
  const {
    orgs: { schools = [] }
  } = useOrgs()
  const { category, schoolId } = useParams()
  const firstId = schools[0].id
  const trackerSource = 'Admin Data Tab'
  const analyticsTracker = adminAnalyticsTracker(trackerSource)
  const filterReducer = (state, action) => {
    switch (action.type) {
      case 'reset':
        return {}
      case 'toggle':
        const newState = { ...state }
        const { payload } = action
        if (state[payload]) {
          delete newState[payload]
        } else {
          newState[payload] = true
        }
        return newState
      default:
        return state
    }
  }
  const [filteredGrades, filterDispatch] = useReducer(filterReducer, {})
  const toggleFilteredGrade = useCallback(grade => {
    filterDispatch({ type: 'toggle', payload: grade })
  }, [])
  const resetFilteredGrades = useCallback(() => {
    filterDispatch({ type: 'reset' })
  }, [])
  const hasGradeFilter = !!Object.keys(filteredGrades).length

  // Reset grade filters on tab change
  useEffect(() => resetFilteredGrades(), [category])

  const filterState = {
    filteredGrades,
    toggleFilteredGrade,
    resetFilteredGrades,
    hasGradeFilter
  }

  return (
    <HeaderLayout
      header={
        <Header title='Data' menu={getMenu(schoolId, analyticsTracker)} />
      }
    >
      <DataContext {...filterState} path={`/data/${category}`} />
      <Switch>
        <Redirect
          exact
          path='/data/:category'
          to={`/data/${category}/${firstId}`}
        />
        <Route
          path='/data/overview/:schoolId'
          render={() => <DataOverview {...filterState} />}
        />
        <Route
          exact
          path='/data/proficiency/:schoolId'
          render={() => (
            <DataProficiency
              filteredGrades={filteredGrades}
              hasGradeFilter={hasGradeFilter}
            />
          )}
        />
        <Route
          exact
          path='/data/motivation/:schoolId'
          render={() => (
            <DataMotivation
              filteredGrades={filteredGrades}
              hasGradeFilter={hasGradeFilter}
            />
          )}
        />
        <Route
          exact
          path='/data/engagement/:schoolId'
          render={() => <DataEngagement 
              filteredGrades={filteredGrades}
              hasGradeFilter={hasGradeFilter}
            />}
        />
        <Route
          exact
          path='/data/git/:schoolId'
          render={() => (
            <DataGitEngagement
              filteredGrades={filteredGrades}
              hasGradeFilter={hasGradeFilter}
            />
          )}
        />
      </Switch>
    </HeaderLayout>
  )
}

export default Data

const getMenu = (schoolId, analyticsTracker) => [
  {
    label: 'Overview',
    url: `/data/overview/${schoolId}`,
    onClick: analyticsTracker.overview
  },
  {
    label: 'Student Proficiency',
    url: `/data/proficiency/${schoolId}`,
    onClick: analyticsTracker.studentProficiency
  },
  {
    label: 'Student Motivation',
    url: `/data/motivation/${schoolId}`,
    onClick: analyticsTracker.studentMotivation
  },
  {
    label: 'Teacher Engagement',
    url: `/data/engagement/${schoolId}`,
    onClick: analyticsTracker.teacherEngagement
  },
  {
    label: 'Level 3 Teachers',
    url: `/data/git/${schoolId}`,
    onClick: analyticsTracker.gitEngagement
  }
]
