import useSchoolGitEngagement from 'hooks/useSchoolGitEngagement'
import GitEngagementTable from 'components/GitEngagementTable'
import { gradeValueToId } from 'shared/utils/data'
import Loading from 'shared/components/Loading'
import { useParams } from 'react-router-dom'
import { Empty } from 'antd'
import React from 'react'

import './DataGitEngagement.less'

export default ({ filteredGrades, hasGradeFilter }) => {
  const { schoolId } = useParams()

  const [rawGitEngagementData, loadingData] = useSchoolGitEngagement(schoolId)

  if (loadingData) {
    return <Loading />
  }

  if (rawGitEngagementData && rawGitEngagementData.length <= 0) {
    return (
      <Empty description='No engagement data yet—check back after teachers have been added to the selected school!' />
    )
  }

  const filteredEngagementData = hasGradeFilter
    ? rawGitEngagementData.filter(({ grades }) =>
        grades.some(grade => filteredGrades[gradeValueToId(grade)])
      )
    : rawGitEngagementData

  if (filteredEngagementData.length <= 0) {
    return (
      <Empty description='No engagement data matches the current grade filter.' />
    )
  }

  return (
    <div className='data-git-engagement'>
      <GitEngagementTable data={filteredEngagementData} />
    </div>
  )
}