import {
  alphaCompare,
  gradesToLabel,
  nameSorter,
  teacherProgressToColor
} from 'shared/utils'
import { Table, Card, Row, Col } from 'antd'
import { gradeValueToId } from 'shared/utils/data'
import palette from 'shared/theme/vars/palette'
import React, { useState } from 'react'
import uniq from 'lodash/uniq'

import './GitEngagementTable.less'

export default ({ data, showOrgs }) => {
  const [sortInfo, setSortInfo] = useState({columnKey: 'name', order: 'ascend'})

  const multiClassGitData = getMultiClassGitData(data)
  const sortedFilteredData = sortGitData(
    data,
    sortInfo,
    multiClassGitData
  )

  const displayColumns = showOrgs ? columnsWithOrgs : columns

  return (
      <Card className='git-engagement-table'>
        <Table
          columns={displayColumns}
          bordered
          dataSource={sortedFilteredData}
          rowKey={(_, i) => i}
          pagination={false}
          sortDirections={['ascend', 'descend', 'ascend']}
          onChange={(pagnation, filters, sorter, extra) => setSortInfo(sorter)}
        />
      </Card>
  )
}

const teacherNameColumn = [
  {
    title: 'Teachers',
    key: 'name',
    sorter: () => 0,
    render: ({ rowSpan, name = {}, priorityColor, badgeColor }) => {
      const obj = {
        children: (
          <Row type='flex' align='middle' justify='space-between'>
            {name.family}, {name.given}
            <div
              className='badge'
              style={{
                backgroundColor: rowSpan === 1 ? priorityColor : badgeColor
              }}
            />
          </Row>
        ),
        props: {
          className: 'teacherName',
          rowSpan
        }
      }

      return obj
    },
    defaultSortOrder: 'ascend'
  }
]

const orgColumns = [
  {
    title: 'School',
    dataIndex: 'schoolName',
    key: 'schoolName',
    sorter: () => 0,
    align: 'center'
  },
  {
    title: 'District',
    dataIndex: 'districtName',
    key: 'districtName',
    sorter: () => 0,
    align: 'center'
  }
]

const teacherDataColumns = [
  {
    title: 'Lessons Taught',
    key: 'lessonsTaught',
    sorter: () => 0,
    render: ({ modulesTaughtData, lessonsTaught }) => {
      const benchmark = 25
      const benchmarkMet = parseInt(lessonsTaught, 10) >= benchmark
      return (
        <Row justify='center' align='middle' type='flex'>
          <Row type='flex' className='lessons-taught-progress'>
            {modulesTaughtData.length > 0 && !benchmarkMet ? (
              modulesTaughtData.map(module => (
                <Col
                  className='default-progress-bar'
                  style={{ width: `${module.progress}%` }}
                />
              ))
            ) : benchmarkMet ? (
              <Col className='full-progress-bar' />
            ) : null}
          </Row>
          <span className='benchmark-proportion'>{`${lessonsTaught} / ${benchmark}`}</span>
        </Row>
      )
    },
    align: 'center'
  },
  {
    title: 'Last Lesson Taught',
    key: 'lastLessonTaught',
    sorter: () => 0,
    render: ({ timeSinceLastLesson, priorityColor }) => {
      return (
        <span style={{ color: priorityColor }}>
          {timeSinceLastLesson || 'NA'}
        </span>
      )
    },
    align: 'center'
  },
  {
    title: 'Current Lesson',
    key: 'currentLesson',
    render: ({ moduleName, lessonIndex }) => {
      if (moduleName && lessonIndex !== null) {
        return `${moduleName} / Lesson ${lessonIndex + 1}`
      } else {
        return 'NA'
      }
    },
    align: 'center'
  },
  {
    title: 'Grade',
    key: 'grades',
    sorter: () => 0,
    render: ({ grades }) => gradesToLabel(grades),
    align: 'center'
  }
]

const columns = [...teacherNameColumn, ...teacherDataColumns]
const columnsWithOrgs = [...teacherNameColumn, ...orgColumns, ...teacherDataColumns]

/**
 * @function dateCompare
 * @description A function for sorting teachers by time since last lesson taught.
 * Note: Null values are sorted to bottom of list regardless of sort order
 * @param {Object} a
 * @param {Object} b
 * @param {String} sortOrder
 * @returns {Number}
 */
function dateCompare(a, b, sortOrder) {
  const multiplier = sortOrder === 'ascend' ? 1 : -1
  const aTs = a.lastLessonTaughtTs
  const bTs = b.lastLessonTaughtTs

  if (aTs && bTs) {
    return multiplier * (new Date(bTs) - new Date(aTs))
  } else {
    return !aTs ? 1 : -1
  }
}

/**
 * @function gradeSorter
 * @description A function for sorting classes by grade. Classes of the same
 * grade will be sorted by lastlessonTaughtTs if the classes have the same
 * teacher, otherwise they will be sorted by name.
 * @param {Object} a
 * @param {Object} b
 * @param {String} sortOrder
 * @returns {Number}
 */
function gradeSorter(a, b, sortOrder) {
  const multiplier = sortOrder === 'ascend' ? 1 : -1
  const { grades: aGrade, id: aTeacherId } = a
  const { grades: bGrade, id: bTeacherId } = b

  const aGradeId = gradeValueToId(aGrade[0])
  const bGradeId = gradeValueToId(bGrade[0])

  if (aGradeId === bGradeId) {
    if (aTeacherId === bTeacherId) {
      return (
        multiplier *
        (new Date(b.lastLessonTaughtTs) - new Date(a.lastLessonTaughtTs))
      )
    }

    return multiplier * nameSorter(a, b)
  }

  return multiplier * (aGradeId - bGradeId)
}

/**
 * @function lessonsTaughtSorter
 * @description A function for sorting classes by lessons taught.
 * @param {(Number|Object)} a
 * @param {(Number|Object)} b
 * @param {String} sortOrder
 * @returns {Number}
 */
function lessonsTaughtSorter(a, b, sortOrder) {
  const aLessonsTaught = typeof a === 'number' ? a : a.lessonsTaught
  const bLessonsTaught = typeof b === 'number' ? b : b.lessonsTaught

  const multiplier = sortOrder === 'ascend' ? 1 : -1

  return multiplier * (aLessonsTaught - bLessonsTaught)
}

/**
 * @function getMultiClassGitData
 * @description Calculates an object of data for GITs with multiple classes.
 * The data contains min/max values for all sortable columns.
 * @param {Array.<Object>} filteredEngagementData
 * @returns {Object}
 */
function getMultiClassGitData(filteredEngagementData) {
  const multipleClasses = {} // keys - teacherIds, values - array of class objects
  const multiClassGitData = {}
  const teachers = uniq(
    filteredEngagementData.map(({ id: teacherId }) => teacherId)
  )

  filteredEngagementData.forEach(data => {
    const { id: teacherId, lastLessonTaughtTs, lessonsTaught } = data
    const newData = {
      ...data,
      priorityColor: teacherProgressToColor(lastLessonTaughtTs, lessonsTaught)
    }

    if (multipleClasses[teacherId]) {
      multipleClasses[teacherId].push(newData)
    } else {
      multipleClasses[teacherId] = [newData]
    }
  })

  teachers.forEach(teacher => {
    if (multipleClasses[teacher].length === 1) {
      delete multipleClasses[teacher]
    }
  })

  Object.keys(multipleClasses).forEach(teacher => {
    const classes = multipleClasses[teacher]
    const lastIndex = classes.length - 1
    const priorityValues = classes.map(
      ({ priorityColor }) => priorityColorToNum(priorityColor)
    )

    classes.sort((a, b) => gradeSorter(a, b, 'ascend'))
    multiClassGitData[teacher] = {
      badgeColor: priorityNumToColor(Math.min(...priorityValues)),
      numOfClasses: classes.length,
      name: {
        min: classes[0],
        max: classes[0]
      },
      grades: {
        min: classes[0],
        max: classes[lastIndex]
      }
    }

    const lessonsTaughtValues = classes.map(({ lessonsTaught }) => lessonsTaught)
    multiClassGitData[teacher].lessonsTaught = {
      min: Math.min(...lessonsTaughtValues),
      max: Math.max(...lessonsTaughtValues)
    }

    const classesByTs = [...classes].sort((a, b) => dateCompare(a, b, 'ascend'))
    const filteredClasses = classesByTs.filter(
      ({ lastLessonTaughtTs }) => lastLessonTaughtTs !== null
    )

    multiClassGitData[teacher].lastLessonTaught = {
      min: classesByTs[0],
      max: filteredClasses[filteredClasses.length - 1] || classesByTs[lastIndex]
    }
  })

  return multiClassGitData
}

/**
 * @function sortGitData
 * @description Sorts filteredEngagementData taking into account multiClassGitData
 * of GITs with multiple classes.
 * @param {Array.<Object>} filteredEngagementData
 * @param {Object} sortInfo
 * @param {Object} multiClassGitData
 * @returns {Array.<Object>}
 */
function sortGitData(filteredEngagementData, sortInfo, multiClassGitData) {
  const { columnKey, order } = sortInfo
  const multiplier = order === 'ascend' ? 1 : -1
  
  filteredEngagementData.sort((a, b) => {
    const data = {
      a,
      b,
      sortInfo,
      multiClassGitData
    }
    if (columnKey === 'name') {
      const { id: aTeacherId } = a
      const { id: bTeacherId } = b

      if (aTeacherId === bTeacherId) {
        return gradeSorter(a, b, 'ascend')
      }

      return multiplier * nameSorter(a, b)
    } else if (columnKey === 'districtName') {
      return (
        alphaCompare(a, b, 'districtName') * multiplier ||
        alphaCompare(a, b, 'schoolName') ||
        nameSorter(a, b)
      )
    } else if (columnKey === 'schoolName') {
      return alphaCompare(a, b, 'schoolName') * multiplier || nameSorter(a, b)
    } else if (columnKey === 'lessonsTaught') {
      return sortWithMulipleClasses(data, lessonsTaughtSorter)
    } else if (columnKey === 'lastLessonTaught') {
      return sortWithMulipleClasses(data, dateCompare)
    } else if (columnKey === 'grades') {
      return sortWithMulipleClasses(data, gradeSorter)
    }
  })

  const hasRowSpan = {}
  // add rowSpan, priorityColor, and badgeColor to all classes
  // Note: badgeColor is only set to a color if rowSpan is greater than 1, i.e. teacher name will be rendered
  const sortedFilteredData = filteredEngagementData.map(classData => {
    const { id: teacherId, lessonsTaught, lastLessonTaughtTs } = classData
    let badgeColor = ''
    let rowSpan

    if(multiClassGitData[teacherId]) {
      if(hasRowSpan[teacherId]) {
        rowSpan = 0
      } else {
        hasRowSpan[teacherId] = true
        rowSpan = multiClassGitData[teacherId].numOfClasses
        badgeColor = multiClassGitData[teacherId].badgeColor
      }
    } else {
      rowSpan = 1
    }

    return {
      ...classData,
      rowSpan,
      badgeColor,
      priorityColor: teacherProgressToColor(lastLessonTaughtTs, lessonsTaught)
    }
  })

  return sortedFilteredData
}

/**
 * @function sortWithMulipleClasses
 * @description A higher-order sort function that takes into account GITs with
 * multiple classes when sorting. The sort considers the lowest/min value when
 * sorting by ascending order and the highest/max value when sorting by
 * descending order.
 * @param {Object} data
 * @param {Function} sorterFn
 * @returns {Number}
 */
function sortWithMulipleClasses(data, sorterFn) {
  const { a, b, sortInfo, multiClassGitData } = data
  const { columnKey, order } = sortInfo
  const { id: aTeacherId } = a
  const { id: bTeacherId } = b

  if (aTeacherId === bTeacherId) {
    return sorterFn(a, b, order)
  }

  const aClass = multiClassGitData[aTeacherId]
    ? order === 'ascend'
      ? multiClassGitData[aTeacherId][columnKey].min
      : multiClassGitData[aTeacherId][columnKey].max
    : a
  const bClass = multiClassGitData[bTeacherId]
    ? order === 'ascend'
      ? multiClassGitData[bTeacherId][columnKey].min
      : multiClassGitData[bTeacherId][columnKey].max
    : b

  return sorterFn(aClass, bClass, order)
}

const priorities = [palette['@red'], palette['@yellow'], palette['@green']]
const priorityColorToNum = priorityColor => priorities.indexOf(priorityColor)
const priorityNumToColor = priorityNum => priorities[priorityNum]