/**
 * @file a hook for getting GIT engagement data by school
 * @author Giovanni Bonilla
 */
import { useEffect, useState } from 'react'
import { rpc } from 'shared/utils/api'

/**
 * @function useSchoolGitEngagement
 * @param {string} schoolId
 * @returns {Array}
 */
const useSchoolGitEngagement = schoolId => {
  const [gitEngagementData, setGitEngagementData] = useState(null)
  const [loadingData, setLoadingData] = useState(true)

  useEffect(() => {
    setLoadingData(true)
    setGitEngagementData(null)
    rpc('school.getGitEngagement', {
      school: schoolId
    }).then(({ gitEngagementData }) => {
      setGitEngagementData(gitEngagementData)
      setLoadingData(false)
    })
  }, [schoolId])

  return [gitEngagementData, loadingData]
}

export default useSchoolGitEngagement
