/**
 * @file a hook for getting school proficiency data by class
 * @author Julius Diaz Panoriñgan
 */

import { useFirestoreValues } from 'shared/hooks/firestore'
import { classesBySchool } from 'shared/firestore/refs'
import { gradeValueToId } from 'shared/utils/data'
import { useEffect, useState } from 'react'
import { rpc } from 'shared/utils/api'

const MIN_GRADE = -1 // pre-k
const MAX_GRADE = 6

/**
 * Given a schoolId and districtId, loads the school proficiency data from the
 * school.getProficiencyData API endpoint. The return array's first element is an array
 * of proficiency data, or null if there is no data yet. The return array's
 * second element is a boolean indicating whether the data is currently loading.
 * @function useSchoolProficiency
 * @param {string} schoolId
 * @param {string} districtId
 * @returns {Array}
 */
const useSchoolProficiency = (schoolId, districtId) => {
  const [allClasses = [], allClassesLoading] = useFirestoreValues(
    classesBySchool({ id: schoolId, district: districtId })
  )
  const [proficiencyData, setProficiencyData] = useState(null)
  const [loading, setLoading] = useState(true)

  // when school id changes, reset
  useEffect(() => {
    setProficiencyData(null)
    setLoading(true)
  }, [schoolId])

  // only request school data after all classes finish loading
  useEffect(() => {
    if (!allClassesLoading) {
      rpc('school.getProficiencyData', { school: schoolId }).then(({ proficiencyData: schoolProficiencyData }) => {
        // place class data obj in main obj if grade in range
        const studentClassesObj = allClasses.reduce(
          (obj, { displayName, grade, id }) => {
            const gradeNum = gradeValueToId(grade)
            if (MIN_GRADE <= gradeNum && gradeNum <= MAX_GRADE) {
              obj[id] = {
                displayName,
                gradeNum,
                gradeText: grade
              }
            }
            return obj
          },
          {}
        )

        // aggregate and format class data
        const studentClassesData = schoolProficiencyData
          .filter(({ class: c }) => studentClassesObj[c]) // id exists in data obj
          .map(c => {
            const classId = c.class
            const p1 = parseInt(c.p1, 10)
            const p2 = parseInt(c.p2, 10)
            const p3 = parseInt(c.p3, 10)
            const p4 = parseInt(c.p4, 10)
            const numWithProficiencyData = p1 + p2 + p3 + p4
            return {
              classId,
              numLessons: parseInt(c.num_lessons, 10),
              avgPracticeTime: parseFloat(c.avg_lesson_practice_time),
              p1,
              p2,
              p3,
              p4,
              noProficiencyData: parseInt(c.no_proficiency_data, 10),
              // for a class w/ no proficiency data yet, we use the sentinel 0
              avgProficiency: numWithProficiencyData
                ? (p1 + 2 * p2 + 3 * p3 + 4 * p4) / numWithProficiencyData
                : 0,
              ...studentClassesObj[classId]
            }
          })

        setProficiencyData(studentClassesData)
        setLoading(false)
      })
    }
  }, [allClassesLoading])

  return [proficiencyData, loading]
}

export default useSchoolProficiency
