import fetch, { fetchEncodeJSON } from 'redux-effects-fetch'
import { createFirestoreInstance } from 'redux-firestore'
import createTracker from 'shared/analytics/middleware'
import { applyMiddleware, createStore } from 'redux'
import localStorage from 'redux-effects-localstorage'
import getConfig from 'shared/config/getConfig'
import { cookieLogin } from 'shared/ducks/user'
import { createBrowserHistory } from 'history'
import { adminRoles } from 'shared/utils/data'
import createDebounce from 'redux-debounced'
import * as middlewares from './middleware'
import rootReducer from './reducers'
import effects from 'redux-effects'
import thunk from 'redux-thunk'
require('moment')
require('moment-duration-format')

const history = createBrowserHistory()
const config = getConfig()
window.firebase.initializeApp(config)
window.firebase
  .auth()
  .setPersistence(window.firebase.auth.Auth.Persistence.NONE)
const firestore = window.firebase.firestore()
firestore.settings({ timestampsInSnapshots: true })

const script = document.createElement('script')
script.type = 'text/javascript'
script.src = 'https://apis.google.com/js/api.js'

document.getElementsByTagName('head')[0].appendChild(script)
const rrfbConfig = store => ({
  firebase: window.firebase,
  dispatch: store.dispatch,
  createFirestoreInstance,
  config: {
    userProfile: 'users',
    useFirestoreForProfile: true,
    updateProfileOnLogin: false,
    oneListenerPerPath: true,
    logListenerError: true,
    enableLogging: false,
    onAuthStateChanged: (user, _, dispatch) => {
      if (!user) {
        dispatch(cookieLogin())
          .then(user => {
            return user.getIdTokenResult()
          })
          .then(({ claims }) => {
            if (!adminRoles.includes(claims.role) && !claims.codingLead) {
              return Promise.reject('not_admin')
            }
          })
          .catch(() => {
            window.location.assign(config.clientUrl)
          })
      }
    }
  }
})

export { history, config, firestore, rrfbConfig }
export default () => {
  const middleware = [
    middlewares.session,
    createDebounce(),
    thunk.withExtraArgument({ history }),
    fetchEncodeJSON,
    effects,
    localStorage(window.localStorage),
    fetch
  ].concat(process.env.NODE_ENV === 'production' ? createTracker() : [])
  const store = createStore(
    rootReducer,
    {}, // initial state
    applyMiddleware(...middleware)
  )
  return store
}
